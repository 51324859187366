import React from "react"
import {
  TextField,
  Select,
  MenuItem,
  Checkbox,
  FormControl,
  InputLabel,
  FormControlLabel,
  Typography,
  makeStyles,
} from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  textField: {
    backgroundColor: theme.palette.white,
  },
}))

const ActivityInput = ({ parameter, value = "", onChange, forceUpdate }) => {
  const classes = useStyles()

  const renderParameter = (parameter) => {
    switch (parameter.type) {
      case "integer":
        return (
          <TextField
            key={parameter.key}
            label={parameter.name}
            type='number'
            variant='outlined'
            InputProps={{
              inputProps: { min: parameter.min, max: parameter.max },
            }}
            InputLabelProps={{ shrink: true }}
            value={value}
            onChange={(e) => onChange(parseInt(e.target.value, 10))
            }
            helperText={parameter.description}
            fullWidth
            margin='normal'
            className={classes.textField}
          />
        )
      case "enum":
        return (
          <FormControl
            key={parameter.key}
            className={classes.formControl}
            fullWidth>
            <InputLabel>{parameter.name}</InputLabel>
            <Select
              value={value}
              variant="outlined"
              onChange={(e) => onChange(e.target.value)}>
              {parameter.values?.map((value) => (
                <MenuItem key={value} value={value}>
                  {value}
                </MenuItem>
              )) || []}
            </Select>
            <Typography variant='caption'>{parameter.description}</Typography>
          </FormControl>
        )
      case "boolean":
        return (
          <FormControlLabel
            key={parameter.key}
            control={
              <Checkbox
                checked={value}
                onChange={(e) => onChange(e.target.checked)}
                name={parameter.key}
              />
            }
            label={
              <div>
                <Typography variant='body1'>{parameter.name}</Typography>
                <Typography variant='caption'>
                  {parameter.description}
                </Typography>
              </div>
            }
          />
        )
      default:
        return null
    }
  }

  if (!parameter || !parameter.type) {
    return <></>
  }
  return <div>{renderParameter(parameter)}</div>
}

export default ActivityInput
