import moment from "moment"
import React, { useState, useEffect } from "react"
import { useParams, useHistory, withRouter } from "react-router-dom"
import { useForm } from "react-hook-form"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { connect } from "react-redux"

import {
  Grid,
  Paper,
  Button,
  FormControl,
  FormLabel,
  TextField,
  Typography,
  Link,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import AddIcon from "@material-ui/icons/Add"

import {
  getLevels,
  getUserThunk,
  createAssignment,
  createAssignmentReset,
  getAssignments,
} from "../../../actions/index"
import ActivityForm from "./ActivityForm"
import LevelForm from "./LevelForm"
import Divider from "../sharedComponents/Divider"
import NFButton from "../sharedComponents/NFButton"

const ACTIVITY = {
  days: [],
  levels: [],
  time_start: '09:00',
  time_end: '12:00',
  date_start: moment().format("YYYY-MM-DD"),
  date_end: moment().add(7, 'days').format("YYYY-MM-DD"),
}

const useStyles = makeStyles((theme) => ({
  topRow: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  form: {
    marginTop: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
  },
  divider: {
    width: "75%",
    display: "block",
    margin: `${theme.spacing(1)} 0`,
    border: 0,
    borderBottomWidth: "1px",
    borderStyle: "solid",
    borderColor: theme.palette.gray[400],
  },
  paper: {
    borderRadius: "0.75rem",
    boxShadow: "none",
    padding: "2rem",
  },
  formGroup: {
    marginTop: theme.spacing(2),
  },
  textField: {
    margin: theme.spacing(1),
    width: 160,
  },
  buttonPrimary: {
    height: "3rem",
    padding: "0.5rem 0.75rem",
    fontSize: "15px",
    fontWeight: 500,
    borderRadius: "0.5rem",
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
      backgroundColor: theme.palette.orange[300],
    },
    backgroundColor: theme.palette.orange[200],
    color: theme.palette.white,
    maxWidth: "16rem",
    marginTop: theme.spacing(1),
  },
  buttonSecondary: {
    height: "3rem",
    padding: "0.5rem 0.75rem",
    fontSize: "15px",
    fontWeight: 500,
    borderRadius: "0.5rem",
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
      backgroundColor: theme.palette.gray[100],
    },
    backgroundColor: theme.palette.gray[200],
    color: theme.palette.gray[600],
    maxWidth: "16rem",
    marginTop: theme.spacing(1),
  },
  messageContainer: {
    marginTop: theme.spacing(3),
  },
}))

const CreateAssignment = ({
  levels,
  levelsFetched,
  getLevelsAction,
  createAssignment,
  createAssignmentReset,
  creating,
  createSuccess,
  createError,
  getUser,
  user,
  getAssignments,
}) => {
  let { id } = useParams()
  const classes = useStyles()

  const [activities, setActivities] = useState([
    {...ACTIVITY}
  ]);

  const [duration, setDuration] = useState(0)
  const [message, setMessage] = useState("")
  let history = useHistory()

  useEffect(() => {
    if (createSuccess) {
      setMessage("Assignments created successfully!")
      setActivities([]) // Clear the form
      createAssignmentReset()
      getAssignments(id)
      history.push(`/user-profile/${id}`)
    } else if (createError) {
      setMessage(`Error creating assignments: ${createError}`)
    }
  }, [createSuccess, createError, createAssignmentReset, history, id])

  useEffect(() => {
    getLevelsAction()
  }, [getLevelsAction])

  useEffect(() => {
    const userId = id
    if (userId) {
      getUser(userId)
    }
  }, [getUser, id])

  // const schema = yup.array().of(
  //   yup.object({
  //     days: yup.array.of(yup.string()).required(),
  //     time_start: yup.string().required(),
  //     time_end: yup.string().required(),
  //     date_start: yup.string().required(),
  //     date_end: yup.string().required(),
  //     levels: yup.array.of(yup.string()).required(),
  //   })
  // )

  // const { register, handleSubmit, setValue, errors, reset } = useForm({
  //   resolver: yupResolver(schema),
  // })

  const addActivity = () => {
    setActivities([...activities, { ...ACTIVITY }])
    console.log(activities)
  }

  const setActivity = (idx, key, value) => {
    const newActivities = [...activities]
    newActivities[idx][key] = value
    setActivities(newActivities)
  }

  const handleActivityChange = (index, updatedActivity) => {
    const newActivities = [...activities];
    newActivities[index] = updatedActivity;
    setActivities(newActivities);
  }

  const setLevels = (idx, value) => {
    const newActivities = [...activities]
    newActivities[idx].levels = value
    setActivities(newActivities)
  }

  const handleDurationChange = (event) => {
    setDuration(event.target.value)
  }

  const onSubmit = (e) => {
    e.preventDefault()
    if (activities.length === 0) {
      setMessage("Please add at least one assignment.")
      return
    }
    createAssignment(id, activities)
  }

  return (
    <>
      <Grid
        container
        spacing={3}
        xs={12}
        direction='row'
        className={classes.topRow}>
        <Grid item xs={2} spacing={3}>
          <NFButton
            type='button'
            variant='secondary'
            onClick={() => history.go(-1)}>
            Back
          </NFButton>
        </Grid>
        <Grid item xs={10} spacing={3}>
          <h1>
            New Assignment: {user.first_name} {user.last_name}
          </h1>
        </Grid>
      </Grid>
      <Grid container item xs={12} direction='column'>
        <Paper className={classes.paper}>
          <form noValidate>
            <Grid container item xs={12}>
              <Typography variant='body1'>
                {message && <p>{message}</p>}
              </Typography>
              {activities.map((activity, index) => (
                <Grid
                  container
                  item
                  xs={12}
                  spacing={2}
                  className={classes.activity}>
                  <Grid item xs={12} lg={6} direction='column'>
                    <ActivityForm
                      key={index}
                      activity={activity}
                      index={index}
                      onActivityChange={handleActivityChange}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6} direction='column'>
                    <LevelForm
                      levels={levels}
                      selectedLevels={activity.levels}
                      setLevels={setLevels}
                      index={index}
                    />
                  </Grid>
                  {/* {index !== activities.length - 1 && <Divider />} */}
                  <Divider />
                </Grid>
              ))}
            </Grid>
            <Grid container item xs={12} direction='column'>
              <FormControl component='fieldset' className={classes.formGroup}>
                <FormLabel component='legend'>
                  (Optional) How much time per day should the patient spend on
                  this assignment?
                </FormLabel>
                <TextField
                  id='duration'
                  value={duration || ""}
                  onChange={handleDurationChange}
                  label='Minimum Duration'
                  variant='outlined'
                  type='number'
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
              <Button
                className={classes.buttonSecondary}
                onClick={() => addActivity()}>
                <AddIcon />
                Add Another Activity
              </Button>
              <Button className={classes.buttonPrimary} onClick={onSubmit}>
                {creating ? "Creating..." : "Create Assignments"}
              </Button>
            </Grid>
          </form>
        </Paper>
      </Grid>
    </>
  )
}

const mapStateToProps = (state) => ({
  levels: state.gameLevelReducer.levels,
  levelsFetched: state.gameLevelReducer.hasFetched,
  creating: state.assignmentReducer.creating,
  createSuccess: state.assignmentReducer.createSuccess,
  createError: state.assignmentReducer.createError,
  user: state.userReducer.viewed_user,
  fetchingUser: state.userReducer.fetchingUser,
  fetchUserSuccess: state.userReducer.fetchUserSuccess,
  fetchUserFailure: state.userReducer.fetchUserFailure,
})

const mapDispatchToProps = (dispatch) => ({
  getLevelsAction: () => dispatch(getLevels()),
  getUser: (id) => dispatch(getUserThunk(id)),
  createAssignmentReset: () => dispatch(createAssignmentReset()),
  createAssignment: (id, activities) =>
    dispatch(createAssignment(id, activities)),
  getAssignments: (id) => dispatch(getAssignments(id)),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreateAssignment)
)
