import {
  GET_LEVELS_REQUEST,
  GET_LEVELS_SUCCESS,
  GET_LEVELS_FAILURE,
  GET_LEVEL_REQUEST,
  GET_LEVEL_SUCCESS,
  GET_LEVEL_FAILURE,
  GET_LEVEL_CLEAR,
} from "../actions/types"

const INITIAL_STATE = {
  level: {},
  fetchingLevel: false,
  hasFetchedLevel: false,
  levels: [],
  fetchingLevels: false,
  hasFetched: false,
  fetchError: null,
}

const gameLevelReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_LEVELS_REQUEST:
      return {
        ...state,
        hasFetched: true,
      }
    case GET_LEVELS_SUCCESS:
      return {
        ...state,
        levels: action.payload,
      }
    case GET_LEVELS_FAILURE:
      return {
        ...state,
        fetchError: action.payload,
      }
    case GET_LEVEL_REQUEST:
      return {
        ...state,
        hasFetchedLevel: true,
      }
    case GET_LEVEL_SUCCESS:
      return {
        ...state,
        level: action.payload,
      }
    case GET_LEVEL_FAILURE:
      return {
        ...state,
        fetchError: action.payload,
      }
    case GET_LEVEL_CLEAR:
      return {
        ...state,
        level: {},
        hasFetchedLevel: false,
      }
    default:
      return state
  }
}

export default gameLevelReducer
