import React from "react"
import { useState, useEffect } from "react"
import { useParams, useHistory, useLocation } from "react-router-dom"
import { connect, useDispatch, useSelector } from "react-redux"
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos"
import {
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Slider,
  Snackbar,
  Switch,
  Typography,
} from "@material-ui/core"
import { Alert } from "@material-ui/lab"
import { makeStyles } from "@material-ui/core/styles"

import NFButton from "../sharedComponents/NFButton"
import ActivityInput from "./ActivityInput"
import {
  getLevel,
  getLevelClear,
  updateActivity,
  updateActivityReset,
} from "../../../actions/index"

const TOPLEVEL_ACTIVITY_PARAMS = [
  "completion_condition_mode",
  "timer",
  "is_adaptive_difficulty",
  "difficulty",
  "completion_clears",
  "completion_duration",
]

const useStyles = makeStyles((theme) => ({
  titleRow: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(6),
    borderRadius: "0.25rem",
    boxShadow: "none",
    border: `1px solid ${theme.palette.blue[200]}`,
  },
  formGroup: {
    marginBottom: theme.spacing(3),
  },
  slider: {
    width: "100%",
  },
  buttonPrimary: {
    marginTop: theme.spacing(2),
  },
}))

const AssignmentEdit = ({ level, levelFetched, getLevel }) => {
  let loadedActivity = useLocation().state?.activity

  const dispatch = useDispatch()
  useEffect(() => {
    console.log("AssignmentEdit clearing level")
    dispatch(getLevelClear())
  }, [dispatch])

  loadedActivity.activity_parameters =
    loadedActivity.activity_parameters ||
    level.activity_parameters.map((param) => ({ [param.key]: param.default }))
  loadedActivity.gameplay_parameters =
    loadedActivity.gameplay_parameters ||
    level.gameplay_parameters.map((param) => ({ [param.key]: param.default }))

  const { updating, updateSuccess, updateError } = useSelector(
    (state) => state.assignmentReducer
  )

  const [showSnackbar, setShowSnackbar] = useState(false)
  const history = useHistory()
  let { id } = useParams()
  const [activity, setActivity] = useState({ ...loadedActivity })
  const [forceUpdate, setForceUpdate] = useState(0) // deep object modification requires a force update
  const classes = useStyles()

  useEffect(() => {
    if (updateSuccess !== null) {
      setShowSnackbar(true)
      if (updateSuccess) {
        dispatch(updateActivityReset())
        history.push(`/user-profile/${id}`)
      }
    }
  }, [dispatch, updateSuccess, history, id])

  useEffect(() => {
    getLevel(loadedActivity.game_level.id)
  }, [getLevel, loadedActivity.game_level])

  const nonTopLevelActivityParams =
    level.activity_parameters &&
    Object.fromEntries(
      Object.entries(level.activity_parameters).filter(
        ([key]) => !TOPLEVEL_ACTIVITY_PARAMS.includes(key)
      )
    )

  const handleSubmit = (event) => {
    event.preventDefault()
    dispatch(
      updateActivity(activity.id, {
        activity_parameters: {
          ...activity.activity_parameters,
          is_edited: true,
        },
        gameplay_parameters: activity.gameplay_parameters,
      })
    )
  }

  const handleActivityParamChange = (key, value) => {
    setActivity({
      ...activity,
      activity_parameters: { ...activity.activity_parameters, [key]: value },
    })
  }

  const handleGameParamChange = (key, value) => {
    setActivity({
      ...activity,
      gameplay_parameters: { ...activity.gameplay_parameters, [key]: value },
    })
  }

  const handleDifficultyChange = (value) => {
    const newGameplayParams = Object.entries(level.gameplay_parameters).reduce(
      (acc, [key, param]) => {
        if (param.is_difficulty_dependent === true) {
          acc[key] = Math.floor(
            level.gameplay_parameters[key].max * (value / 10)
          )
        }
        return acc
      },
      {}
    )

    setActivity({
      ...activity,
      gameplay_parameters: {
        ...activity.gameplay_parameters,
        ...newGameplayParams,
      },
      activity_parameters: {
        ...activity.activity_parameters,
        difficulty: value,
      },
    })
    setForceUpdate(forceUpdate + 1)
  }

  const handleActivityChange = (key, value) => {
    setActivity({ ...activity, [key]: value })
  }

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return
    }
    setShowSnackbar(false)
  }

  if (!activity || !level.name) {
    return <Typography variant='h2'>Loading Activity...</Typography>
  }

  return (
    <>
      <Grid container direction='column'>
        <Grid
          item
          container
          direction='row'
          alignItems='center'
          spacing={2}
          className={classes.titleRow}>
          <Grid item>
            <NFButton
              type='button'
              variant='secondary'
              onClick={() => history.go(-1)}>
              <ArrowBackIosIcon />
              Back
            </NFButton>
          </Grid>
          <Grid item>
            <Typography
              variant='h2'
              style={{ fontSize: "2rem", fontWeight: 500 }}>
              {`Edit Activity: ${activity.game_level.name}`}
            </Typography>
          </Grid>
        </Grid>
        <Grid item>
          <Paper className={classes.paper}>
            <form onSubmit={handleSubmit}>
              <Grid container direction='column' spacing={3}>
                <Typography variant='h6'>Completion Settings</Typography>
                <Grid item md={6} xs={12}>
                  <FormControl
                    component='fieldset'
                    className={classes.formGroup}>
                    <FormLabel component='legend'>Completion Mode</FormLabel>
                    <RadioGroup
                      aria-label='gender'
                      name='completion_condition_mode'
                      value={
                        activity.activity_parameters.completion_condition_mode
                      }
                      onChange={(_, v) =>
                        handleActivityParamChange(
                          "completion_condition_mode",
                          v
                        )
                      }>
                      {level.activity_parameters.completion_condition_mode.values.map(
                        (mode) => (
                          <FormControlLabel
                            key={mode}
                            value={mode}
                            control={<Radio />}
                            label={mode}
                          />
                        )
                      )}
                    </RadioGroup>
                  </FormControl>
                </Grid>

                {activity.activity_parameters.completion_condition_mode ===
                  "CLEARS" && (
                  <Grid item xs={12} md={6}>
                    <ActivityInput
                      parameter={level.activity_parameters.completion_clears}
                      value={activity.activity_parameters.completion_clears}
                      onChange={(v) =>
                        handleActivityParamChange("completion_clears", v)
                      }
                      forceUpdate={forceUpdate}
                    />
                  </Grid>
                )}

                {activity.activity_parameters.completion_condition_mode ===
                  "DURATION" && (
                  <Grid item xs={12} md={6}>
                    <ActivityInput
                      parameter={level.activity_parameters.completion_duration}
                      value={activity.activity_parameters.completion_duration}
                      onChange={(v) =>
                        handleActivityParamChange("completion_duration", v)
                      }
                    />
                  </Grid>
                )}

                <Grid item xs={12} md={6}>
                  <ActivityInput
                    parameter={level.activity_parameters.timer}
                    value={activity.activity_parameters.timer}
                    onChange={(v) => handleActivityParamChange("timer", v)}
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <FormControl
                    component='fieldset'
                    className={classes.formGroup}>
                    <FormLabel component='legend'>
                      Adaptive Difficulty
                    </FormLabel>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={
                            activity.activity_parameters.is_adaptive_difficulty
                          }
                          onChange={(_, newValue) =>
                            handleActivityParamChange(
                              "is_adaptive_difficulty",
                              newValue
                            )
                          }
                        />
                      }
                      label={
                        activity.activity_parameters.is_adaptive_difficulty
                          ? "Enabled"
                          : "Disabled"
                      }
                    />
                    <Typography variant='caption'>
                      {
                        level.activity_parameters.is_adaptive_difficulty
                          .description
                      }
                    </Typography>
                  </FormControl>
                </Grid>

                {!activity.activity_parameters.is_adaptive_difficulty && (
                  <Grid item xs={12}>
                    <FormControl
                      component='fieldset'
                      className={classes.formGroup}>
                      <FormLabel component='legend'>
                        Difficulty (1-10)
                      </FormLabel>
                      <Slider
                        value={activity.activity_parameters.difficulty}
                        onChange={(_, newValue) =>
                          handleDifficultyChange(newValue)
                        }
                        min={1}
                        max={10}
                        step={1}
                        marks
                        valueLabelDisplay='auto'
                        className={classes.slider}
                      />
                    </FormControl>
                  </Grid>
                )}

                {!activity.activity_parameters.is_adaptive_difficulty &&
                  level.gameplay_parameters && (
                    <Typography variant='h6'>Difficulty Settings</Typography>
                  )}
                {!activity.activity_parameters.is_adaptive_difficulty &&
                  level.gameplay_parameters &&
                  Object.entries(level.gameplay_parameters)
                    .filter(([k, l]) => l.is_difficulty_dependent)
                    ?.map(([key, param]) => (
                      <Grid item xs={12} key={key}>
                        <ActivityInput
                          parameter={{ ...param, key: key }}
                          value={activity.gameplay_parameters[key]}
                          onChange={(v) => handleGameParamChange(key, v)}
                        />
                      </Grid>
                    ))}

                <Typography variant='h6'>Activity Settings</Typography>
                {Object.entries(nonTopLevelActivityParams)?.map(
                  ([key, param]) => (
                    <Grid item xs={12} key={key}>
                      <ActivityInput
                        parameter={{ ...param, key: key }}
                        value={activity.activity_parameters[key]}
                        onChange={(v) => handleActivityParamChange(key, v)}
                      />
                    </Grid>
                  )
                )}

                {Object.entries(level.gameplay_parameters).filter(
                  ([k, l]) => !l.is_difficulty_dependent
                ).length > 0 && (
                  <Typography variant='h6'>{level.name} Settings</Typography>
                )}
                {Object.entries(level.gameplay_parameters)
                  .filter(([k, l]) => !l.is_difficulty_dependent)
                  ?.map(([key, param]) => (
                    <Grid item xs={12} md={6} key={key}>
                      <ActivityInput
                        parameter={{ ...param, key: key }}
                        value={activity.gameplay_parameters[key]}
                        onChange={(v) => handleGameParamChange(key, v)}
                      />
                    </Grid>
                  ))}
                <Grid item xs={12}>
                  <NFButton type='submit' variant='primary'>
                    {updating ? "Updating..." : "Update Assignment"}
                    {updating && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </NFButton>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Grid>
      </Grid>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}>
        <Alert
          onClose={handleCloseSnackbar}
          severity={updateSuccess ? "success" : "error"}>
          {updateSuccess || updateError}
        </Alert>
      </Snackbar>
    </>
  )
}

const mapStateToProps = (state) => ({
  level: state.gameLevelReducer.level,
  levelFetched: state.gameLevelReducer.hasFetchedLevel,
})

const mapDispatchToProps = (dispatch) => ({
  getLevel: (id) => dispatch(getLevel(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AssignmentEdit)
