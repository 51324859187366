import React, { Component } from "react"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import Login from "../sharedComponents/Login"
import ForgotPassword from "../sharedComponents/ForgotPassword"
import ResetPassword from "../sharedComponents/ResetPassword"

import Organizations from "./adminView/Organizations"
import CreateAssignment from "./Assignments/CreateAssignment"
import AssignmentEdit from "./Assignments/AssignmentEdit"

import Users from "../organizationView/Users"
import UserFeedbackGraph from "../d3Components/UserFeedbackGraph"
import AppBar from "./sharedComponents/AppBar"

import { connect } from "react-redux"
import { signOut } from "../../actions"

import "../../styles/v2/style.scss"
import PermissionsPage from "../adminView/PermissionsPage"
import DeepDataDive from "../adminView/DeepDataDive"
import { ThemeProvider } from "@material-ui/core/styles"

import PermissionsService from "../../lib/PermissionsSystem"

// register component
import RegisterContainer from "../sharedComponents/Register/RegisterContainer"

// navbar 1.1
import NavbarContainer from "./sharedComponents/Navbar/NavbarContainer"
import NavbarMobileContainer from "../sharedComponents/Navbar/NavbarMobile"

// Patient Profile 1.1

// Main scss for App.js
import "./App.scss"

import asyncComponent from "../../highOrderComponents/asyncComponent/asyncComponent"

import theme from "../theme"

const AsyncScheduler = asyncComponent(() =>
  import("../scheduledSessions/index")
)
const AsyncPatientDashboard = asyncComponent(() =>
  import("../v2/dashboards/PatientDashboard")
)
const AsyncOrgDashboard = asyncComponent(() =>
  import("../dashboards/OrganizationDashboard")
)
const AsyncPractitionerDashboard = asyncComponent(() =>
  import("../dashboards/PractitionerDashboard")
)
const AsyncError404Page = asyncComponent(() => import("../Error/404"))

const AsyncBillingInformation = asyncComponent(() => import("../Billing"))

class App extends Component {
  getPrivilege = async () => {
    parseInt(localStorage.getItem("privilege"), 10)
  }

  renderRoutes = () => {
    if (this.props.isAuthenticated && this.props.privilege !== undefined) {
      this.getPrivilege()
      if (this.props.privilege === 0) {
        return (
          <ThemeProvider theme={theme}>
            <Switch>
              {/* <Route path="/cognitive-profile" component={UserGeneralGraph} /> */}
              <Route
                path='/user-profile/:id'
                component={AsyncPatientDashboard}
              />
              <Route
                path='/practitioner-profile/:id'
                component={AsyncPractitionerDashboard}
              />
              <Route path='/mood' component={() => <UserFeedbackGraph />} />
              <Route
                exact
                path='/'
                component={() => <Users user_types={["PATIENT"]} />}
              />
              <Route
                path='/patient'
                component={() => <Users user_types={["PATIENT"]} />}
              />
              <Route
                path='/patients/:id/assignments/new'
                component={() => <CreateAssignment />}
              />
              <Route
                path='/patients/:id/assignments/:assignment_id/edit'
                component={() => <AssignmentEdit />}
              />
              <Route
                path='/organizations'
                component={() => <Organizations />}
              />
              {PermissionsService.HasPermission("admin.billing.read") && (
                <Route path='/billing' component={AsyncBillingInformation} />
              )}

              <Route path='/org-profile/:id' component={AsyncOrgDashboard} />
              {/* Setting the route to the component for the monthly view schedule */}
              <Route path='/schedule' component={AsyncScheduler} />
              <Route component={AsyncError404Page} />
            </Switch>
          </ThemeProvider>
        )
      } else if (this.props.privilege === 1) {
        return (
          <ThemeProvider theme={theme}>
            <Switch>
              {PermissionsService.HasPermission("admin.billing.read") && (
                <Route path='/billing' component={AsyncBillingInformation} />
              )}
              <Route
                exact
                path='/'
                component={() => <Users user_types={["PATIENT"]} />}
              />
              <Route
                path='/organizations'
                component={() => <Organizations />}
              />
              {/* <Route path="/user-profile/:id" component={(props) => <UserGeneralGraph {...props}/>} /> */}
              <Route
                path='/user-profile/:id'
                component={AsyncPatientDashboard}
              />
              <Route
                path='/patients/:id/assignments/new'
                component={() => <CreateAssignment />}
              />
              <Route
                path='/patients/:id/assignments/:assignment_id/edit'
                component={() => <AssignmentEdit />}
              />
              <Route
                path='/practitioner-profile/:id'
                component={AsyncPractitionerDashboard}
              />
              <Route path='/org-profile/:id' component={AsyncOrgDashboard} />
              <Route path='/permissions' component={PermissionsPage} />
              <Route path='/deep_data' component={DeepDataDive} />
              {/*<Route exact path="/practitioners" component={AsyncPractitionersComponent} />
              <Route exact path="/patients" component={()=><Users user_types={["PATIENT"]}/>} />*/}
              <Route path='/schedule' component={AsyncScheduler} />
              <Route component={AsyncError404Page} />
            </Switch>
          </ThemeProvider>
        )
      }
    } else {
      return (
        <ThemeProvider theme={theme}>
          <div className='container mt-3 login-page-container'>
            <Switch>
              <Route
                exact
                path='/'
                component={() => (
                  <Login
                    isAuthenticated={this.props.isAuthenticated}
                    getPrivilege={this.props.getPrivilege}></Login>
                )}
              />
              <Route
                exact
                path='/forgot_password'
                component={() => <ForgotPassword />}
              />
              <Route
                exact
                path='/password_reset'
                component={() => <ResetPassword />}
              />
              <Route exact path='/register' component={RegisterContainer} />
              <Route component={AsyncError404Page} />
            </Switch>
          </div>
        </ThemeProvider>
      )
    }
  }

  // hides and displays the appbar
  renderAppBar = () => {
    // if the authentication is false, the AppBar hides
    if (!this.props.isAuthenticated) {
      return (
        <Router>
          {/* <div className="delete"> */}
          {this.renderRoutes()}
          {/* </div> */}
        </Router>
      )
    }
    // else, the AppBar will display
    else {
      // navbar and dynamic components rendered here
      return (
        <Router>
          <div className='container-layout'>
            <div className='container-nav'>
              <NavbarContainer
                isAuthenticated={this.props.isAuthenticated}
                privilege={this.props.privilege}
                logoutUser={this.props.signOut}
                user={this.props.user}
                org={this.props.org}
                isExpanded={this.props.isExpanded}
              />

              <NavbarMobileContainer
                isAuthenticated={this.props.isAuthenticated}
                privilege={this.props.privilege}
                logoutUser={this.props.signOut}
                user={this.props.user}
                org={this.props.org}
              />
            </div>
            <div
              className={`container-content ${
                !!!this.props.isExpanded && "full"
              }`}>
              <div className='container-header'>
                <AppBar
                  isAuthenticated={this.props.isAuthenticated}
                  privilege={this.props.privilege}
                  logoutUser={this.props.signOut}
                  user={this.props.user}
                  org={this.props.org}
                />
              </div>
              <div className='container-page'>{this.renderRoutes()}</div>
            </div>
          </div>

          {/* <div className="app-container">
            <div className="app-container-flex-item">{this.renderRoutes()}</div>
          </div> */}
        </Router>
      )
    }
  }

  render() {
    return <div>{this.renderAppBar()}</div>
  }
}

const mapStateToProps = ({ authReducer, navReducer }) => {
  // console.log(state.authReducer);
  return {
    isAuthenticated: authReducer.isAuthenticated,
    privilege: authReducer.privilege,
    user: authReducer.user,
    org: authReducer.org,
    isExpanded: navReducer.isExpanded,
  }
}

export default connect(mapStateToProps, { signOut })(App)
