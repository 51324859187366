import { updateUserSuccess } from "actions";
import {
  GET_USERS_BY_ORG,
  GET_USER,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_FAILURE,
  CREATE_USER,
  UPDATE_USER,
  UPDATE_SELECTED_USER,
  TOGGLE_LOGIN_FOR_USERS,
  ARCHIVE_USER,
  GET_SELECTED_USER,
  SUBMIT_USER_DATA,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  SET_LANGUAGE,
} from "../actions/types";

const INITIAL_STATE = {
  language: "en-US",
  users: [],
  selectedUser: "",
  user: {},
  viewed_user: {},
  patient_data: {},
  hasFetched: false,
  response: {},
  userRatings: [],
  userSessions: [],
  sessionType: "",
  isArchive: false,
  include_archived: true,
  userRadarChartData: [],
  fetchingUser: false,
  fetchUserSuccess: null,
  fetchUserFailure: null,
  updatingUser: false,
  updateUserSuccess: null,
  updateUserMessage: "",
};

const userReducer =  (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_LANGUAGE:
      return {
        ...state,
        language: action.payload
      };
    case GET_USERS_BY_ORG:
      return {
        ...state,
        users: action.payload,
        user_list: action.payload,
        hasFetched: true,
        include_archived: true,
      };
    case CREATE_USER:
      return { ...state, hasFetched: true, response: action.payload };
    case UPDATE_USER:
      return { ...state, hasFetched: true };
    case UPDATE_SELECTED_USER:
      return { ...state, selectedUser: action.userEmail };
    case TOGGLE_LOGIN_FOR_USERS:
      return { ...state, response: action.payload };
    case ARCHIVE_USER:
      return {
        ...state,
        user_list: [...state.user_list].map((user) => {
          if (user.id === action.payload.user_id) {
            user.status = action.payload.status;
            console.log("Updated user status");
          }
          return user;
        }),
        selectedUser: action.payload.user_id,
        isArchive: false,
      };
    case GET_SELECTED_USER:
      return { ...state, selectedUser: action.id };
    case GET_USER:
      return {
        ...state,
        viewed_user: action.payload,
        viewed_patient_data: action.payload.patient_data,
      };
      case GET_USER_REQUEST:
        return {
          ...state,
          fetchingUser: true,
          fetchUserSuccess: false,
          fetchUserFailure: false,
        }
      case GET_USER_SUCCESS:
        return {
          ...state,
          fetchingUser: false,
          fetchUserSuccess: true,
          fetchUserFailure: false,
          viewed_user: action.payload
        }
      case GET_USER_FAILURE:
        return {
          ...state,
          fetchingUser: false,
          fetchUserSuccess: false,
          fetchUserFailure: true,
          fetchError: action.payload,
        }
      case UPDATE_USER_REQUEST:
        return {
          ...state,
          updatingUser: true,
        }
      case UPDATE_USER_SUCCESS:
        return {
          ...state,
          updatingUser: false,
          updateUserSuccess: true,
          updateUserMessage: action.payload,
        }
      case UPDATE_USER_FAILURE:
        return {
          ...state,
          updatingUser: false,
          updateUserSuccess: false,
          updateUserMessage: action.payload,
        }
    
    case SUBMIT_USER_DATA: {
      return {
        ...state,
      };
    }
    default:
      return state;
  }
};

export default userReducer
