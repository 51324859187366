import React from "react"
import { connect } from "react-redux"
import "./Navbar.scss"
import { Link } from "react-router-dom"

import { toggleNav } from "../../../../actions/NavActions"
import { useTranslation } from "../../../../i18n/translate"

// Absolute import paths are not working with this configuration & CRA version
import UserGroupOutlineIcon from "../../../../assets/images/UserGroupOutline.svg"
import CurrencyDollarOutlineIcon from "../../../../assets/images/CurrencyDollarOutline.svg"
import OfficeBuildingOutlineIcon from "../../../../assets/images/OfficeBuildingOutline.svg"
import LogoutOutlineIcon from "../../../../assets/images/LogoutOutline.svg"
import { ArrowBack, ArrowForward } from "@material-ui/icons"

import Logo from "../../../../assets/images/neurofit_icon.png"
import Logotype from "../../../../assets/images/neurofit_logotype.svg"
import PermissionsService from "../../../../lib/PermissionsSystem"

const Navbar = ({ onSignOutClick, isExpanded, features }) => {
  console.log('navbar')
  let a = 1
  a += 1
  const t = useTranslation()

  const shouldShowBilling = !(features && features.find((f) => f.is_active && f.name === "is_billing_hidden"))

  return (
    <div
      className={`container-nav-inner ${
        isExpanded ? "expanded" : "collapsed"
      }`}>
      <Link to={"/"} className='container-nav-logo'>
        <img src={Logo} alt='neurofit brain icon' className='logo' />
        {isExpanded && (
          <img src={Logotype} alt='neurofit' className='logotype' />
        )}
      </Link>

      <div className='navbar-links-container'>
        <Link
          style={{ textDecoration: "none" }}
          to={"/"}
          className='navbar-link'>
          <img src={UserGroupOutlineIcon} alt='Users' />
          {isExpanded && <span>{t("USERS")}</span>}
        </Link>
        <Link
          style={{ textDecoration: "none" }}
          to={"/organizations"}
          className='navbar-link'>
          <img src={CurrencyDollarOutlineIcon} alt='Users' />
          {isExpanded && <span>{t("ORGANIZATIONS")}</span>}
        </Link>
        {shouldShowBilling && PermissionsService.HasPermission("admin.billing.read") && (
          <Link
            style={{ textDecoration: "none" }}
            to={"/billing"}
            className='navbar-link'>
            <img src={OfficeBuildingOutlineIcon} alt='Users' />
            {isExpanded && <span>{t("BILLING")}</span>}
          </Link>
        )}
        <div className='nav-boundary'></div>
        <div
          style={{ cursor: "pointer" }}
          onClick={onSignOutClick}
          className='navbar-link'>
          <img src={LogoutOutlineIcon} alt='Users' />
          {isExpanded && <span>{t("SIGN_OUT")}</span>}
        </div>
      </div>
      <div className='navbar-links-container collapse-toggle'>
        <div
          style={{ cursor: "pointer" }}
          onClick={toggleNav}
          className='navbar-link'>
          {isExpanded ? (
            <div>
              <ArrowBack className='circle' />
              {isExpanded && <span>{t("COLLAPSE")}</span>}
            </div>
          ) : (
            <ArrowForward className='circle' />
          )}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    isExpanded: state.navReducer.isExpanded,
    features: state.authReducer.features,
  }
}

export default connect(mapStateToProps)(Navbar)
